import { Sites } from '@thunder/core/src/services/baas/directus/schema';

export class Site extends Sites {
	constructor(data: unknown) {
		super();

		Object.assign(this, data);
	}

	toJSON() {
		return {
			...this,
		};
	}
}
