import type { User } from '@/thunder/modules/user/user.model';
import { authentication, createDirectus, rest } from '@directus/sdk';
import type { Schema } from '@thunder/core/src/services/baas/directus/schema';

export const useBaas = () => {
	const config = useRuntimeConfig();
	const { login } = useDirectusAuth();

	const client = createDirectus<Schema>(config.public.directus.url)
		.with(rest())
		.with(authentication());

	return {
		...client,
		health: async () =>
			await fetch(`${config.public.directus.url}/server/health`),
		ready: async () => await fetch(`${config.public.directus.url}/server/ping`),

		request: (async options => {
			const { status } = useAuth();
			const isLoggedIn = status.value === 'authenticated';

			/**
			 * If we are not logged in, reset the abilities
			 * and redirect to login
			 */
			if (
				!isLoggedIn &&
				!['/auth/password/request', '/auth/password/reset'].includes(
					options().path,
				)
			) {
				const userData = useCookie<User | null>('userData');
				const { query, fullPath, path } = useRoute();

				userData.value = null;

				navigateTo({
					name: 'login',
					query: {
						...query,
						to: fullPath !== '/' ? path : undefined,
					},
				});

				throw new Error('Could not complete request. Please try again.');
			}

			return client.request(options);
		}) as typeof client.request,

		/**
		 *
		 * @param username is an email in this instance
		 * @param password
		 */
		async login(username: string, password: string) {
			return await login({
				email: username,
				password,
			});
		},
	};
};
