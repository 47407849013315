import { Customers } from '@thunder/core/src/services/baas/directus/schema';

export class Customer extends Customers {
	constructor(data: unknown) {
		super();

		Object.assign(this, data);
	}

	public get fullName(): string {
		return `${this.name ?? ''} ${this.surname ?? ''}`;
	}

	toJSON() {
		return {
			...this,
		};
	}
}
