import currency from 'currency.js';

export const getReadCurrency = (amount: number, symbol?: string) => {
	return currency(amount, {
		separator: ',',
		precision: 2,
		fromCents: true,
		symbol: symbol ?? 'ZAR ',
	});
};

export const setCurrencyValue = (amount: number, symbol?: string) => {
	return currency(amount, {
		separator: ',',
		precision: 2,
		symbol: symbol ?? 'ZAR ',
	});
};
