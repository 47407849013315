export class Customers {
  communication_preferences?: unknown | null;
  date_created?: string | null;
  date_updated?: string | null;
  id: number;
  loyalty_points?: number | null;
  name?: string | null;
  pensioner?: boolean | null;
  vip?: boolean | null;
  phone?: string | null;
  sort?: number | null;
  status?: string | null;
  surname?: string | null;
  user_created?: string | DirectusUsers | null;
  user_updated?: string | null;
  vouchers: any[] | Vouchers[];
  work_orders: any[] | WorkOrders[];
  service_notes?: string | null;
}

export class DirectusActivity {
  action: string;
  collection: string;
  comment?: string | null;
  id: number;
  ip?: string | null;
  item: string;
  origin?: string | null;
  revisions: any[] | DirectusRevisions[];
  timestamp: string;
  user?: string | DirectusUsers | null;
  user_agent?: string | null;
}

export class DirectusCollections {
  accountability?: string | null;
  archive_app_filter: boolean;
  archive_field?: string | null;
  archive_value?: string | null;
  collapse: string;
  collection: string;
  color?: string | null;
  display_template?: string | null;
  group?: string | DirectusCollections | null;
  hidden: boolean;
  icon?: string | null;
  item_duplication_fields?: unknown | null;
  note?: string | null;
  preview_url?: string | null;
  singleton: boolean;
  sort?: number | null;
  sort_field?: string | null;
  translations?: unknown | null;
  unarchive_value?: string | null;
  versioning: boolean;
}

export class DirectusDashboards {
  color?: string | null;
  date_created?: string | null;
  icon: string;
  id: string;
  name: string;
  note?: string | null;
  panels: any[] | DirectusPanels[];
  user_created?: string | DirectusUsers | null;
}

export class DirectusExtensions {
  bundle?: string | null;
  enabled: boolean;
  folder: string;
  id: string;
  source: string;
}

export class DirectusFields {
  collection: string | DirectusCollections;
  conditions?: unknown | null;
  display?: string | null;
  display_options?: unknown | null;
  field: string;
  group?: string | DirectusFields | null;
  hidden: boolean;
  id: number;
  interface?: string | null;
  note?: string | null;
  options?: unknown | null;
  readonly: boolean;
  required?: boolean | null;
  sort?: number | null;
  special?: unknown | null;
  translations?: unknown | null;
  validation?: unknown | null;
  validation_message?: string | null;
  width?: string | null;
}

export class DirectusFiles {
  charset?: string | null;
  description?: string | null;
  duration?: number | null;
  embed?: string | null;
  filename_disk?: string | null;
  filename_download: string;
  filesize?: number | null;
  focal_point_x?: number | null;
  focal_point_y?: number | null;
  folder?: string | DirectusFolders | null;
  height?: number | null;
  id: string;
  location?: string | null;
  metadata?: unknown | null;
  modified_by?: string | DirectusUsers | null;
  modified_on: string;
  storage: string;
  tags?: unknown | null;
  title?: string | null;
  type?: string | null;
  uploaded_by?: string | DirectusUsers | null;
  uploaded_on: string;
  width?: number | null;
}

export class DirectusFlows {
  accountability?: string | null;
  color?: string | null;
  date_created?: string | null;
  description?: string | null;
  icon?: string | null;
  id: string;
  name: string;
  operation?: string | DirectusOperations | null;
  operations: any[] | DirectusOperations[];
  options?: unknown | null;
  status: string;
  trigger?: string | null;
  user_created?: string | DirectusUsers | null;
}

export class DirectusFolders {
  id: string;
  name: string;
  parent?: string | DirectusFolders | null;
}

export class DirectusMigrations {
  name: string;
  timestamp?: string | null;
  version: string;
}

export class DirectusNotifications {
  collection?: string | null;
  id: number;
  item?: string | null;
  message?: string | null;
  recipient: string | DirectusUsers;
  sender?: string | DirectusUsers | null;
  status?: string | null;
  subject: string;
  timestamp?: string | null;
}

export class DirectusOperations {
  date_created?: string | null;
  flow: string | DirectusFlows;
  id: string;
  key: string;
  name?: string | null;
  options?: unknown | null;
  position_x: number;
  position_y: number;
  reject?: string | DirectusOperations | null;
  resolve?: string | DirectusOperations | null;
  type: string;
  user_created?: string | DirectusUsers | null;
}

export class DirectusPanels {
  color?: string | null;
  dashboard: string | DirectusDashboards;
  date_created?: string | null;
  height: number;
  icon?: string | null;
  id: string;
  name?: string | null;
  note?: string | null;
  options?: unknown | null;
  position_x: number;
  position_y: number;
  show_header: boolean;
  type: string;
  user_created?: string | DirectusUsers | null;
  width: number;
}

export class DirectusPermissions {
  action: string;
  collection: string;
  fields?: unknown | null;
  id: number;
  permissions?: unknown | null;
  presets?: unknown | null;
  role?: string | DirectusRoles | null;
  validation?: unknown | null;
}

export class DirectusPresets {
  bookmark?: string | null;
  collection?: string | null;
  color?: string | null;
  filter?: unknown | null;
  icon?: string | null;
  id: number;
  layout?: string | null;
  layout_options?: unknown | null;
  layout_query?: unknown | null;
  refresh_interval?: number | null;
  role?: string | DirectusRoles | null;
  search?: string | null;
  user?: string | DirectusUsers | null;
}

export class DirectusRelations {
  id: number;
  junction_field?: string | null;
  many_collection: string;
  many_field: string;
  one_allowed_collections?: unknown | null;
  one_collection?: string | null;
  one_collection_field?: string | null;
  one_deselect_action: string;
  one_field?: string | null;
  sort_field?: string | null;
}

export class DirectusRevisions {
  activity: number | DirectusActivity;
  collection: string;
  data?: unknown | null;
  delta?: unknown | null;
  id: number;
  item: string;
  parent?: number | DirectusRevisions | null;
  version?: string | DirectusVersions | null;
}

export class DirectusRoles {
  admin_access: boolean;
  app_access: boolean;
  description?: string | null;
  enforce_tfa: boolean;
  icon: string;
  id: string;
  ip_access?: unknown | null;
  name: string;
  users: any[] | DirectusUsers[];
}

export class DirectusSessions {
  expires: string;
  ip?: string | null;
  origin?: string | null;
  share?: string | DirectusShares | null;
  token: string;
  user?: string | DirectusUsers | null;
  user_agent?: string | null;
}

export class DirectusSettings {
  auth_login_attempts?: number | null;
  auth_password_policy?: string | null;
  basemaps?: unknown | null;
  custom_aspect_ratios?: unknown | null;
  custom_css?: string | null;
  default_appearance: string;
  default_language: string;
  default_theme_dark?: string | null;
  default_theme_light?: string | null;
  id: number;
  mapbox_key?: string | null;
  module_bar?: unknown | null;
  project_color: string;
  project_descriptor?: string | null;
  project_logo?: string | DirectusFiles | null;
  project_name: string;
  project_url?: string | null;
  public_background?: string | DirectusFiles | null;
  public_favicon?: string | DirectusFiles | null;
  public_foreground?: string | DirectusFiles | null;
  public_note?: string | null;
  report_bug_url?: string | null;
  report_error_url?: string | null;
  report_feature_url?: string | null;
  storage_asset_presets?: unknown | null;
  storage_asset_transform?: string | null;
  storage_default_folder?: string | DirectusFolders | null;
  theme_dark_overrides?: unknown | null;
  theme_light_overrides?: unknown | null;
  theming_group: string;
}

export class DirectusShares {
  collection: string | DirectusCollections;
  date_created?: string | null;
  date_end?: string | null;
  date_start?: string | null;
  id: string;
  item: string;
  max_uses?: number | null;
  name?: string | null;
  password?: string | null;
  role?: string | DirectusRoles | null;
  times_used?: number | null;
  user_created?: string | DirectusUsers | null;
}

export class DirectusSyncIdMap {
  created_at?: string | null;
  id: number;
  local_id: string;
  sync_id: string;
  table: string;
}

export class DirectusTranslations {
  id: string;
  key: string;
  language: string;
  value: string;
}

export enum UserCommunicationPreferences {
  ManagementNotificationBadReview = 'management_notification_bad_review',
  ManagementNotificationInquiry = 'management_notification_inquiry',
}

export class DirectusUsers {
  appearance?: string | null;
  auth_data?: unknown | null;
  avatar?: string | DirectusFiles | null;
  communication_preferences?: UserCommunicationPreferences[] | null;
  current_site?: number | Sites | null;
  description?: string | null;
  email?: string | null;
  email_notifications?: boolean | null;
  external_identifier?: string | null;
  first_name?: string | null;
  id: string;
  job_title?: string | null;
  language?: string | null;
  last_access?: string | null;
  last_name?: string | null;
  last_page?: string | null;
  location?: string | null;
  office_phone?: string | null;
  password?: string | null;
  phone?: string | null;
  provider: string;
  role?: string | DirectusRoles | null;
  sites: any[] | JunctionDirectusUsersSites[];
  status: string;
  tags?: string[] | null;
  tfa_secret?: string | null;
  theme_dark?: string | null;
  theme_dark_overrides?: unknown | null;
  theme_light?: string | null;
  theme_light_overrides?: unknown | null;
  title?: string | null;
  token?: string | null;
  work_orders: any[] | WorkOrdersDirectusUsers[];
}

export class DirectusVersions {
  collection: string | DirectusCollections;
  date_created?: string | null;
  date_updated?: string | null;
  hash?: string | null;
  id: string;
  item: string;
  key: string;
  name?: string | null;
  user_created?: string | DirectusUsers | null;
  user_updated?: string | DirectusUsers | null;
}

export class DirectusWebhooks {
  actions: unknown;
  collections: unknown;
  data: boolean;
  headers?: unknown | null;
  id: number;
  method: string;
  migrated_flow?: string | null;
  name: string;
  status: string;
  url: string;
  was_active_before_deprecation: boolean;
}

export class Inquiries {
  customer?: number | Customers | null;
  customer_name?: string | null;
  customer_phone?: string | null;
  date_created?: string | null;
  date_updated?: string | null;
  id: number;
  question?: string | null;
  sort?: number | null;
  status: string;
  user_created?: string | DirectusUsers | null;
  user_updated?: string | DirectusUsers | null;
}

export class JunctionDirectusUsersSites {
  directus_users_id?: string | DirectusUsers | null;
  id: number;
  sites_id?: number | Sites | null;
}

export class Reviews {
  attachments: any[] | ReviewsFiles[];
  customer_name?: string | null;
  customer_phone?: string | null;
  date_created?: string | null;
  date_updated?: string | null;
  id: number;
  message?: string | null;
  rating?: string | null;
  site?: number | Sites | null;
  sort?: number | null;
  type?: string | null;
  user_created?: string | DirectusUsers | null;
  user_updated?: string | null;
  work_order?: number | WorkOrders | null;
}

export class ReviewsFiles {
  directus_files_id?: string | DirectusFiles | null;
  id: number;
  reviews_id?: number | Reviews | null;
}

export class Services {
  date_created?: string | null;
  date_updated?: string | null;
  id: number;
  name?: string | null;
  sort?: number | null;
  user_created?: string | null;
  user_updated?: string | DirectusUsers | null;
  work_orders: any[] | WorkOrdersServices[];
}

export class Settings {
  date_updated?: string | null;
  excl_services_avg_wash_time: any[] | SettingsServices[];
  loyalty_free_wash_threshold?: number | null;
  id: number;
  user_updated?: string | DirectusUsers | null;
  chat_menu_config?: object | null;
}

export class SettingsServices {
  id: number;
  services_id?: number | Services | null;
  settings_id?: number | Settings | null;
}

export class Sites {
  date_created?: string | null;
  date_updated?: string | null;
  google_review_link?: string | null;
  id: number;
  location?: string | null;
  manager?: string | DirectusUsers | null;
  name?: string | null;
  reviews: any[] | Reviews[];
  sort?: number | null;
  staff: any[] | SitesDirectusUsers[];
  user_created?: string | DirectusUsers | null;
  user_updated?: string | null;
  vouchers: any[] | Vouchers[];
  work_orders: any[] | WorkOrders[];
  phone?: string | null;
  communications_enabled?: boolean | null;
}

export class SitesDirectusUsers {
  directus_users_id?: string | DirectusUsers | null;
  id: number;
  sites_id?: number | Sites | null;
}

export enum VoucherType {
  discount = 'discount',
  free = 'free',
  loyalty = 'loyalty',
}

export enum VouchersStatus {
  created = 'created',
  disbursed = 'disbursed',
  redeemed = 'redeemed',
  archived = 'archived',
}

export class Vouchers {
  amount?: number | null;
  customer?: number | Customers | null;
  date_created?: string | null;
  date_updated?: string | null;
  description?: string | null;
  disbursed_at?: string | null;
  id: number;
  redeemed_at?: string | null;
  ref: string;
  services: number[] | VouchersServices[] | Partial<VouchersServices>[];
  site_redeemed?: number | Sites | null;
  sort?: number | null;
  status?: VouchersStatus | null;
  type: VoucherType;
  user_created?: string | DirectusUsers | null;
  user_updated?: string | null;
  work_order: number | WorkOrders | Partial<WorkOrders>;
}

export type VouchersServices = {
  id: number;
  services_id?: number | Services | null;
  vouchers_id?: number | Vouchers | null;
};

export enum WorkOrderStatus {
  created = 'created',
  processing = 'processing',
  ready_collection = 'ready_collection',
  collected = 'collected',
  archived = 'archived',
}

export enum WorkOrderSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
  x_large = 'x_large',
}

export enum WorkOrderPaymentMethod {
  credit_card = 'credit_card',
  cash = 'cash',
  eft = 'eft',
  bitcoin = 'bitcoin',
  free = 'free',
  loyalty = 'loyalty',
  voucher = 'voucher',
}

export enum WorkOrderPaymentStatus {
  awaiting_payment = 'awaiting_payment',
  paid = 'paid',
}

export enum WorkOrderPaymentType {
  credit = 'credit',
  debit = 'debit',
}

export class WorkOrderTransactions {
  amount?: number | null;
  date_created?: string | null;
  date_updated?: string | null;
  id: number;
  payment_method: WorkOrderPaymentMethod;
  status_payment: WorkOrderPaymentStatus;
  type: WorkOrderPaymentType;
  user_created?: string | null;
  user_updated?: string | null;
  work_order: number | WorkOrders | Partial<WorkOrders>;

  public get work_order_id(): number | null | undefined {
    if (
      this.work_order &&
      typeof this.work_order === 'object' &&
      this.work_order.id
    ) {
      return <number>this.work_order.id;
    }

    // we can safely assume that the customer is a number because of the above IF
    return this.work_order as number | null | undefined;
  }
}

export class WorkOrders {
  booking_number?: number | null;
  booking_ref?: string | null;
  checkin_at?: string | null;
  checkout_at?: string | null;
  communication_audit?: WorkOrderCommunicationAudits[] | null;
  customer?: number | Customers | null;
  date_created?: string | null;
  date_updated?: string | null;
  description?: string | null;
  id: number;
  management_notes?: string | null;
  payments:
    | number[]
    | WorkOrderTransactions[]
    | Partial<WorkOrderTransactions>[];
  price?: number | null;
  reviews: any[] | Reviews[];
  services: number[] | WorkOrdersServices[] | Partial<WorkOrdersServices>[];
  site?: number | Sites | null;
  size?: WorkOrderSize;
  sort?: number | null;
  staff: any[] | WorkOrdersDirectusUsers[];
  status: WorkOrderStatus;
  user_created?: string | null;
  user_updated?: string | DirectusUsers | null;
  ready_collect_at?: string | null;
  start_processing_at?: string | null;
  processing_time_minutes?: number | null;
  waiting_time_minutes?: number | null;

  public get customer_id(): number | null | undefined {
    if (this.customer && typeof this.customer === 'object') {
      return <number>this.customer.id;
    }

    // we can safely assume that the customer is a number because of the above IF
    return this.customer as number | null | undefined;
  }
}

export enum WorkOrderCommunicationAudits {
  WhatsappCheckedIn = 'whatsapp_checked_in',
  WhatsappReadyCollection = 'whatsapp_ready_collection',
  WhatsappCollected = 'whatsapp_collected',
  WhatsappLoyaltyStatus = 'whatsapp_loyalty_status',
}

export class WorkOrdersDirectusUsers {
  directus_users_id?: string | DirectusUsers | null;
  id: number;
  work_orders_id?: number | WorkOrders | null;
}

export class WorkOrdersServices {
  id: number;
  services_id?: number | Services | null;
  work_orders_id?: number | WorkOrders | null;
}

export type Schema = {
  customers: Customers[];
  directus_activity: DirectusActivity[];
  directus_collections: DirectusCollections[];
  directus_dashboards: DirectusDashboards[];
  directus_extensions: DirectusExtensions[];
  directus_fields: DirectusFields[];
  directus_files: DirectusFiles[];
  directus_flows: DirectusFlows[];
  directus_folders: DirectusFolders[];
  directus_migrations: DirectusMigrations[];
  directus_notifications: DirectusNotifications[];
  directus_operations: DirectusOperations[];
  directus_panels: DirectusPanels[];
  directus_permissions: DirectusPermissions[];
  directus_presets: DirectusPresets[];
  directus_relations: DirectusRelations[];
  directus_revisions: DirectusRevisions[];
  directus_roles: DirectusRoles[];
  directus_sessions: DirectusSessions[];
  directus_settings: DirectusSettings;
  directus_shares: DirectusShares[];
  directus_sync_id_map: DirectusSyncIdMap[];
  directus_translations: DirectusTranslations[];
  directus_users: DirectusUsers[];
  directus_versions: DirectusVersions[];
  directus_webhooks: DirectusWebhooks[];
  inquiries: Inquiries[];
  junction_directus_users_sites: JunctionDirectusUsersSites[];
  reviews: Reviews[];
  reviews_files: ReviewsFiles[];
  services: Services[];
  settings: Settings;
  settings_services: SettingsServices[];
  sites: Sites[];
  sites_directus_users: SitesDirectusUsers[];
  vouchers: Vouchers[];
  work_order_transactions: WorkOrderTransactions[];
  work_orders: WorkOrders[];
  work_orders_directus_users: WorkOrdersDirectusUsers[];
  work_orders_services: WorkOrdersServices[];
};
