/**
 * The Directus base client, we need the base client
 * and the nuxt-directus module doesnt seem to prove access to it
 */
export default defineNuxtPlugin(() => {
	const { token } = useAuth();

	if (!token) {
		console.warn('BaaS plugin is being loaded without an auth token');
	}

	const client = useBaas();

	client.setToken(token.value?.split('Bearer ')[1] ?? null);

	return {
		provide: {
			baas: client,
		},
	};
});
