import { default as cashupWlZZ064fimMeta } from "/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/cashup.vue?macro=true";
import { default as forgot_45passwordjObi87comtMeta } from "/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/forgot-password.vue?macro=true";
import { default as indexELUpUGQrykMeta } from "/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/index.vue?macro=true";
import { default as loginv7Ew7XDWKqMeta } from "/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/login.vue?macro=true";
import { default as not_45authorized0xIgXHz4hhMeta } from "/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/not-authorized.vue?macro=true";
import { default as reset_45passwordGNqmI7yKEJMeta } from "/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/reset-password.vue?macro=true";
import { default as indexd5BJRJQtMjMeta } from "/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/work-order/[id]/index.vue?macro=true";
import { default as indexHfZmJpGfRdMeta } from "/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/work-order/create/index.vue?macro=true";
import { default as collectedI1pZAYoujlMeta } from "/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/work-order/status/collected.vue?macro=true";
import { default as completedj7JMSKs4MJMeta } from "/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/work-order/status/completed.vue?macro=true";
import { default as processingqXcMCYm5n4Meta } from "/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/work-order/status/processing.vue?macro=true";
export default [
  {
    name: cashupWlZZ064fimMeta?.name ?? "cashup",
    path: cashupWlZZ064fimMeta?.path ?? "/cashup",
    meta: cashupWlZZ064fimMeta || {},
    alias: cashupWlZZ064fimMeta?.alias || [],
    redirect: cashupWlZZ064fimMeta?.redirect,
    component: () => import("/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/cashup.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordjObi87comtMeta?.name ?? "forgot-password",
    path: forgot_45passwordjObi87comtMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordjObi87comtMeta || {},
    alias: forgot_45passwordjObi87comtMeta?.alias || [],
    redirect: forgot_45passwordjObi87comtMeta?.redirect,
    component: () => import("/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: indexELUpUGQrykMeta?.name ?? "index",
    path: indexELUpUGQrykMeta?.path ?? "/",
    meta: indexELUpUGQrykMeta || {},
    alias: indexELUpUGQrykMeta?.alias || [],
    redirect: indexELUpUGQrykMeta?.redirect,
    component: () => import("/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginv7Ew7XDWKqMeta?.name ?? "login",
    path: loginv7Ew7XDWKqMeta?.path ?? "/login",
    meta: loginv7Ew7XDWKqMeta || {},
    alias: loginv7Ew7XDWKqMeta?.alias || [],
    redirect: loginv7Ew7XDWKqMeta?.redirect,
    component: () => import("/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: not_45authorized0xIgXHz4hhMeta?.name ?? "not-authorized",
    path: not_45authorized0xIgXHz4hhMeta?.path ?? "/not-authorized",
    meta: not_45authorized0xIgXHz4hhMeta || {},
    alias: ["/pages/misc/not-authorized"].concat(not_45authorized0xIgXHz4hhMeta?.alias || []),
    redirect: not_45authorized0xIgXHz4hhMeta?.redirect,
    component: () => import("/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/not-authorized.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordGNqmI7yKEJMeta?.name ?? "reset-password",
    path: reset_45passwordGNqmI7yKEJMeta?.path ?? "/reset-password",
    meta: reset_45passwordGNqmI7yKEJMeta || {},
    alias: reset_45passwordGNqmI7yKEJMeta?.alias || [],
    redirect: reset_45passwordGNqmI7yKEJMeta?.redirect,
    component: () => import("/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: indexd5BJRJQtMjMeta?.name ?? "work-order-id",
    path: indexd5BJRJQtMjMeta?.path ?? "/work-order/:id()",
    meta: indexd5BJRJQtMjMeta || {},
    alias: indexd5BJRJQtMjMeta?.alias || [],
    redirect: indexd5BJRJQtMjMeta?.redirect,
    component: () => import("/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/work-order/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexHfZmJpGfRdMeta?.name ?? "work-order-create",
    path: indexHfZmJpGfRdMeta?.path ?? "/work-order/create",
    meta: indexHfZmJpGfRdMeta || {},
    alias: indexHfZmJpGfRdMeta?.alias || [],
    redirect: indexHfZmJpGfRdMeta?.redirect,
    component: () => import("/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/work-order/create/index.vue").then(m => m.default || m)
  },
  {
    name: collectedI1pZAYoujlMeta?.name ?? "work-order-status-collected",
    path: collectedI1pZAYoujlMeta?.path ?? "/work-order/status/collected",
    meta: collectedI1pZAYoujlMeta || {},
    alias: collectedI1pZAYoujlMeta?.alias || [],
    redirect: collectedI1pZAYoujlMeta?.redirect,
    component: () => import("/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/work-order/status/collected.vue").then(m => m.default || m)
  },
  {
    name: completedj7JMSKs4MJMeta?.name ?? "work-order-status-completed",
    path: completedj7JMSKs4MJMeta?.path ?? "/work-order/status/completed",
    meta: completedj7JMSKs4MJMeta || {},
    alias: completedj7JMSKs4MJMeta?.alias || [],
    redirect: completedj7JMSKs4MJMeta?.redirect,
    component: () => import("/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/work-order/status/completed.vue").then(m => m.default || m)
  },
  {
    name: processingqXcMCYm5n4Meta?.name ?? "work-order-status-processing",
    path: processingqXcMCYm5n4Meta?.path ?? "/work-order/status/processing",
    meta: processingqXcMCYm5n4Meta || {},
    alias: processingqXcMCYm5n4Meta?.alias || [],
    redirect: processingqXcMCYm5n4Meta?.redirect,
    component: () => import("/home/arno/Symbyte/thunderbrothers/thunder-fullstack-app/pages/work-order/status/processing.vue").then(m => m.default || m)
  }
]