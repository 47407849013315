import { Services } from '@thunder/core/src/services/baas/directus/schema';

export class Service extends Services {
	constructor(data: unknown) {
		super();

		Object.assign(this, data);
	}

	toJSON() {
		return {
			...this,
		};
	}
}
