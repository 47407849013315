import { getReadCurrency } from '@/thunder/common/utils/number';
import { Service } from '@/thunder/modules/service/service.model';
import { WorkOrderTransaction } from '@/thunder/modules/work-order-transaction/work-order-transaction.model';
import {
	WorkOrderPaymentStatus,
	WorkOrders,
} from '@thunder/core/src/services/baas/directus/schema';

export enum WorkOrderReadStatus {
	created = 'Created',
	processing = 'Washing',
	ready_collection = 'Ready for Collection',
	collected = 'Collected',
	archived = 'Archived',
}

export enum WorkOrderReadStatusPayment {
	awaiting_payment = 'Awaiting Payment',
	paid = 'Paid',
}

export enum WorkOrderReadPaymentMethod {
	cash = 'Cash',
	credit_card = 'Credit Card',
	eft = 'EFT',
	bitcoin = 'Bitcoin',
	free = 'Free',
	loyalty = 'Loyalty',
	voucher = 'Voucher',
}

export enum WorkOrderReadSize {
	small = 'Small',
	medium = 'Medium',
	large = 'Large',
	x_large = 'X-Large',
}

export class WorkOrder extends WorkOrders {
	constructor(data?: unknown) {
		super();

		Object.assign(this, data);
	}

	/**
	 *
	 * The services are a M2M relation but we would like to get them using only one function call
	 * @returns the current list of services attached
	 */
	public getServices(): Service[] {
		const services: Service[] = [];

		for (const service of this.services) {
			if (
				typeof service === 'object' &&
				typeof service.services_id === 'object' &&
				service.services_id
			) {
				services.push(new Service(service.services_id));
			}
		}

		return services;
	}

	public get isPaidUp(): boolean {
		return (
			this.balance <= 0 &&
			this.getPayments().every(payment => {
				return payment.status_payment === WorkOrderPaymentStatus.paid;
			})
		);
	}

	public getPayments(): WorkOrderTransaction[] {
		const payments: WorkOrderTransaction[] = [];

		for (const payment of this.payments ?? []) {
			if (typeof payment !== 'object') {
				console.warn(
					'Payment is not an object, cannot create WorkOrderTransaction. Load the relationship into the model.',
				);

				return payments;
			}
			payments.push(new WorkOrderTransaction(payment));
		}

		return payments;
	}

	public get readPrice(): string {
		const total = getReadCurrency(<number>this.price, 'ZAR');

		return `${total.value}`;
	}

	public get balance(): number {
		if (!this.price) {
			return 0;
		}

		const payments = this.getPayments()
			.filter(payment => payment.status_payment == WorkOrderPaymentStatus.paid)
			.reduce((accumulator, currentValue) => {
				return accumulator + Number(currentValue.amount);
			}, 0);

		return this.price - payments;
	}

	toJSON() {
		return {
			...this,
		};
	}
}
